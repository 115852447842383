/* 地址列表数据弹窗 */
<template>
  <el-drawer
    v-if="drawer"
    :visible.sync="drawer"
    :wrapperClosable="false"
    :with-header="false"
    direction="rtl"
    size="80%"
    id="addSucai"
    :destroy-on-close="true"
  >
    <el-container style="height:100%">
      <el-header height="auto">
        <fw-header titles="选择地址" />
      </el-header>
      <el-main>
        <vxe-table
          
          class="mytable-scrollbar"
          stripe
          highlight-hover-row
          size="small"
          :border="false"
          ref="xTable1"
          id="toolbar_demo3"
          :data="listData"
          :row-id="defaultKey"
          :radio-config="{ checkRowKey: defaultSelecteRow }"
          row-key
        >
          <vxe-column type="radio" width="60"></vxe-column>
          <vxe-column type="seq" title="序号" width="60"></vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <div>联系人姓名</div>
              <div>手机号</div>
            </template>
            <template #default="{row}">
              <div class="ellipsisText" :title="row.contacts_name || '--'">
                {{ row.contacts_name || "--" }}
              </div>
              <div class="ellipsisText" :title="row.contacts_mobile || '--'">
                {{ row.contacts_mobile || "--" }}
              </div>
            </template>
          </vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <div>所在地区</div>
            </template>
            <template #default="{row}">
              <div class="ellipsisText" :title="row.province + row.city + row.area || '--'">
                {{ row.province + row.city + row.area }}
              </div>
            </template>
          </vxe-column>
          <vxe-column min-width="200">
            <template #header>
              <div>街道地址</div>
            </template>
            <template #default="{row}">
              <div class="ellipsisText" :title="row.address || '--'">
                {{ row.address || "--" }}
              </div>
            </template>
          </vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <div>邮政编码</div>
            </template>
            <template #default="{row}">
              <div class="ellipsisText" :title="row.post_code || '--'">
                {{ row.post_code || "--" }}
              </div>
            </template>
          </vxe-column>
          <vxe-column title="创建人" width="160">
            <template #header>
              <div>创建人</div>
              <div>创建时间</div>
            </template>
            <template #default="{row}">
              <div>{{ row.created_by || "--"}}</div>
              <div>{{ row.created_at || "--"}}</div>
            </template>
          </vxe-column>
          <vxe-column title="更新人" width="160">
            <template #header>
              <div>更新人</div>
              <div>更新时间</div>
            </template>
            <template #default="{row}">
              <div>{{ row.updated_by || "--" }}</div>
              <div>{{ row.updated_at || "--" }}</div>
            </template>
          </vxe-column>
        </vxe-table>
      </el-main>
      <el-footer style="margin: 10px 0">
        <el-row>
          <el-col align="center" :span="24">
            <span class="chongzhiPlain">
              <el-button style="width: 88px" size="small" @click="close">取消</el-button>
              <el-button style="width: 88px" size="small" type="primary" @click="sumbit">
                提交
              </el-button>
            </span>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </el-drawer>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      drawer: false,
      callback: null,
      listData: [],
      rowStyle: {
        //表格行样式
        color: "#333",
        fontSize: "12px",
        height: "56px",
      },
      headerStyle: {
        //表格头部样式
        background: "#F5F5F5",
        color: "#333",
        fontSize: "14px",
        height: "40px",
      },
      defaultSelecteRow: 1,
      defaultKey: "id",
    };
  },
  created() {},
  mounted() {},
  methods: {
    open(val, callback) {
      console.log(val);
      if (val && val.addressInfo) {
        this.defaultSelecteRow = val.addressInfo.id;
      }
      this.callback = callback;
      this.drawer = true;
      this.query();
    },
    close() {
      // this.drawer = false
      this.$emit("closeAddress", false);
    },

    query() {
      this.$axios.get("/base/user/address").then(res => {
        if (res.code == 200) {
          this.listData = res.data;
        }
      });
    },

    sumbit() {
      const records = this.$refs.xTable1.selectRow;
      console.log(records);
      if (!records) {
        return this.$message.error("请先勾选数据!");
      } else {
        this.callback(records);
        this.$emit("closeAddress", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding-left: 25px;
  padding-right: 25px;
}
</style>
