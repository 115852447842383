/* 确认订单 */
<template>
  <div id="main_MyOrder" style="width:1200px; margin:0 auto">
    <el-container style="height:99%;">
      <el-header height="auto">
        <div style="padding:0 20px;background-color:#fff">
          <fw-header
            titles="确认订单"
            :headers="{
              back: true,
            }"
          />
        </div>
        <el-row class="head_info_bar">
          <el-col :span="24" class="head_info">
            <div style="width:48px;"></div>
            <div style="width:80px;"></div>
            <div style="width:259px;">商品信息</div>
            <div style="width:200px;">商品规格</div>
            <div style="width:140px;text-align:center">单位</div>
            <div style="width:140px;">销售单价</div>
            <div style="width:135px;">订购数量</div>
            <div style="width:135px;">金额小计</div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="main_box" v-for="(item, index) in tableList" :key="index">
          <div class="main_head">
            <div>
              <h4>{{ item.seller_company_name || "--" }}</h4>
              <h4 style="margin: 0 15px">({{ item.seller_company_code || "--" }})</h4>
              <i class="el-icon-goods empha" @click="gotoHomePage(item)"></i>
            </div>
          </div>
          <div
            class="main_info"
            v-for="(it, ind) in item.sku"
            :key="ind"
            :style="`background-color:${(ind + 1) % 2 == 0 ? '#f2f2f2' : '#fff'}`"
          >
            <div style="width:48px;text-align: center;">{{ ind + 1 }}</div>
            <div style="width:80px;">
              <el-image
                style="width: 90%; height: 90%"
                :src="it.com_sku.cover_img_path"
                :preview-src-list="[it.com_sku.cover_img_path]"
                fit="contain"
              ></el-image>
            </div>
            <div style="width:255px;">
              <div
                style="margin: 10px 0;width:255px;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.name"
              >
                {{ it.com_sku && it.com_sku.name }}
              </div>
              <div
                style="margin: 10px 0;width:255px;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.serial_number"
              >
                {{ it.com_sku && it.com_sku.serial_number }}
              </div>
              <div
                style="margin: 10px 0;width:255px;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.com_sku_goods.goods_small"
              >
                {{ it.com_sku && it.com_sku.com_sku_goods.goods_small }}
              </div>
              <div
                style="margin: 10px 0;width:255px;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.info"
              >
                {{ it.com_sku && it.com_sku.info }}
              </div>
            </div>
            <div style="width:200px;">
              <div v-if="it.spec_json">
                <div v-for="(itt, indd) in it.spec_json" :key="indd" style="margin: 10px 0">
                  <p
                    class="ellipsisText"
                    :title="itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name"
                    v-if="indd < 2"
                  >
                    {{ itt.spec_name }}&nbsp;{{ itt.spec_value_name }}&nbsp;{{ itt.spec_unit_name }}
                  </p>
                </div>
                <el-popover
                  v-if="it.spec_json.length > 2"
                  placement="right"
                  width="200"
                  trigger="hover"
                >
                  <div style="padding: 10px">
                    <p
                      v-for="(itt, indd) in it.spec_json"
                      :key="indd"
                      :title="itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name"
                    >
                      {{ itt.spec_name }}&nbsp;{{ itt.spec_value_name }}&nbsp;{{
                        itt.spec_unit_name
                      }}
                    </p>
                  </div>
                  <span slot="reference">...</span>
                </el-popover>
              </div>
              <div v-else>
                --
              </div>
            </div>
            <div style="width:140px;text-align:center">
              <div
                class="ellipsisText"
                :title="(it.com_sku && it.com_sku.com_sku_goods.goods_unit_name) || '--'"
              >
                {{ (it.com_sku && it.com_sku.com_sku_goods.goods_unit_name) || "--" }}
              </div>
            </div>
            <div style="width:140px;">
              <div class="ellipsisText" :title="it.com_sku && it.com_sku.price">
                ￥{{ it.com_sku && it.com_sku.price }}
              </div>
            </div>
            <div style="width:135px;">
              <div class="ellipsisText" :title="it.buy_count">
                {{ it.buy_count || "--" }}
              </div>
            </div>
            <div style="width:135px;">
              <div class="ellipsisText" style="fontSize:16px;color:#f74848;fontWeight:600;">
                {{ ((it.com_sku && it.com_sku.price) * Number(it.buy_count)) | formatMoney }}
              </div>
            </div>
          </div>
          <div class="price_bar">
            <p>
              商品总额:
              <span style="fontSize:18px;fontWeight:600;margin-left:10px" :title="item.totalPrice">
                {{ item.totalPrice | formatMoney }}
              </span>
            </p>
            <p>
              折扣:
              <span style="fontSize:18px;fontWeight:600;margin-left:10px">-￥0.00</span>
            </p>
            <p>
              总价:
              <span style="fontSize:18px;fontWeight:600;color:#f74848;margin-left:10px">
                {{ (item.totalPrice - Number(0.0)) | formatMoney }}
              </span>
            </p>
          </div>
          <div class="main_bot">
            <div>
              <span style="margin-right:45px;color:#858585;fontSize:10px">配送至</span>
              <template v-if="is_address">
                <span style="margin-right:15px" :title="item.address.name">
                  {{ item.address.name }}
                </span>
                <span style="margin-right:15px" :title="item.address.mobile">
                  {{ item.address.mobile }}
                </span>
                <span style="margin-right:15px" :title="item.address.address || '--'">
                  {{ renderAddress(item.address) || "--" }}
                </span>
              </template>
              <template v-else>
                --
              </template>
            </div>
            <div>
              <i
                class="el-icon-arrow-right"
                style="fontSize:22px;fontWeight:1000;"
                @click="changeAddress(item)"
              ></i>
            </div>
          </div>
        </div>
      </el-main>
      <div style="margin-top:5px">
        <div class="bot">
          <el-row class="part1">
            <el-col :span="7" :offset="17">
              <span style="width: 120px; display: inline-block;">已选商品</span>
              <span style="fontSize:16px;color:#f74848;fontWeight:600;">
                {{ tableList.length }}单{{ totalOrderMun }}项
              </span>
            </el-col>
          </el-row>
          <el-row class="part1">
            <el-col :span="7" :offset="17">
              <span style="width: 120px; display: inline-block;">商品总额</span>
              <span style="fontSize:16px;color:#f74848;fontWeight:600;">
                {{ totalMoney | formatMoney }}
              </span>
            </el-col>
          </el-row>
          <el-row class="part1">
            <el-col :span="7" :offset="17">
              <span style="width: 120px; display: inline-block;">折扣</span>
              <span style="fontSize:16px;color:#f74848;fontWeight:600;">
                -￥0.00
              </span>
            </el-col>
          </el-row>
          <el-row class="part2">
            <el-col :span="11" :offset="1">
              <el-radio-group v-model="pay_model">
                <el-radio :label="1">先款后货</el-radio>
                <el-radio :label="2">先货后款</el-radio>
              </el-radio-group>
            </el-col>
            <el-col :span="4">应付金额</el-col>
            <el-col :span="4">
              <span style="fontSize:18px;fontWeight:600;color:#f74848;margin-left:10px">
                {{ (totalMoney - Number(0.0)) | formatMoney }}
              </span>
            </el-col>
            <el-col :span="4">
              <button class="button-24" role="button" @click="sumbit">提交</button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-container>
    <!-- <address-draw ref="addressDraw" /> -->
    <address-list-draw ref="addressListDraw" v-if="showAddress" @closeAddress="closeAddress" />

    <fw-choseaddress ref="fwchoseaddress" />
    <!-- 选择 -->
    <addressDraw ref="addressDraw" />
  </div>
</template>
<script>
// import AddressDraw from "./components/addressDraw.vue";
import AddressListDraw from "./components/addressListDraw.vue";
import addressDraw from "@/views/myCaiGou/myOrder/components/addressDraw.vue";
export default {
  name: "confirmOrder",
  components: { AddressListDraw, addressDraw },
  data() {
    return {
      tableList: [],
      totalMoney: null,
      totalOrderMun: null,
      addressInfo: {
        id: null,
        post_code: null,
        contacts_name: null,
        contacts_mobile: null,
        address: null,
        area: null,
        city: null,
        province: null,
      },
      showAddress: false, //地址列表弹窗
      link: null,
      is_address: null,
      pay_model: 1,
      buyer_user_mobile: null, //用户手机号
      sku_info: null, //所选物品参数
      type: 1, //1个人2企业
    };
  },
  created() {
    console.log(JSON.parse(sessionStorage.getItem("choice_list")));
    let choice_list = JSON.parse(sessionStorage.getItem("choice_list"));
    if (!choice_list) return;
    this.sku_info = choice_list.sku_info;
    this.type = choice_list.type;

    this.query();
    this.getUser();
  },
  beforeDestroy() {
    sessionStorage.removeItem("choice_list");
  },
  mounted() {},
  methods: {
    // submit() {
    //   let com_sku = [];
    //   this.tableList.forEach(item => {
    //     item.sku.forEach(it => {
    //       com_sku.push({
    //         seller_company_id: item.seller_company_id, //供应商id
    //         id: it.com_sku_id, //商品id
    //         price: it?.com_sku.price || 0, //价格
    //         quantity: it.buy_count, //数量
    //         delivery_date: it.delivery_date || "", //交货日期
    //         delivery_cycle: it.delivery_cycle, //交货周期 天
    //         address: "", //交货地址
    //         cover_img_path: it?.com_sku.cover_img_path, //商品图片
    //         remark: it.remark,
    //       });
    //     });
    //   });
    //   console.log(this.addressInfo);
    //   let param = {
    //     address: {
    //       //订单收货地址
    //       province_name: this.addressInfo.province, //省名称
    //       city_name: this.addressInfo.city_name, //市名称
    //       area_name: this.addressInfo.area_name, //区名称
    //       address: this.addressInfo.address, //地址
    //       mobile: this.addressInfo.contacts_mobile, //手机号
    //       name: this.addressInfo.contacts_name, //收货人姓名
    //       post: this.addressInfo.post_code, //邮编
    //     },
    //     com_sku,
    //   };
    //   this.$axios.postJSON("/shop/public/common/buyer_cart/place_order", param).then(res => {
    //     let data = {
    //       token: sessionStorage.getItem("AccessToken"),
    //       domin: location.origin,
    //       order_id: res.data,
    //       order_type: "shop",
    //     };
    //     window.open(`https://pay.feiwin.cn/?data=${JSON.stringify(data)}`);
    //   });
    // },
    getUser() {
      this.$axios.get("/base/user/login_info").then(res2 => {
        if (res2.code == 200) {
          this.buyer_user_mobile = res2.data.staff.mobile;
        }
      });
    },
    query() {
      // console.log(this.$route.query.type);
      // let type = 1; //0企业1个人
      // if (this.$route.query.type) {
      //   type = 0;
      // }
      let type = this.type;
      if (type == 1) {
        this.getUserData();
      } else {
        this.getCompanyData();
      }
    },
    getUserData() {
      this.$axios.get("/base/user/address").then(res2 => {
        if (res2.code == 200) {
          if (res2.data) {
            let data = res2.data
              .map(i => {
                if (i.is_default) {
                  return i;
                }
              })
              .filter(l => l != undefined);
            console.log(data);
            this.addressInfo = data[0];
            this.is_address = true;
          } else {
            this.$message.warning("未设置配送收货地址");
            this.is_address = false;
          }
          this.$axios
            .postJSON("/shop/public/common/order_submit_view", {
              sku_info: this.sku_info,
              page_size: 10000,
            })
            .then(res => {
              if (res.code == 200) {
                if (res.data.data && res.data.data.length > 0) {
                  let data = res.data.data;
                  data.forEach(el => {
                    let total = el.sku.map(i => {
                      let obj = i.com_sku?.price * Number(i.buy_count);
                      return obj;
                    });
                    el["totalPrice"] = this.getSum(total);
                    el["address"] = {
                      province_name: this.addressInfo.province,
                      city_name: this.addressInfo.city,
                      area_name: this.addressInfo.area,
                      address: this.addressInfo.address,
                      mobile: this.addressInfo.contacts_mobile,
                      name: this.addressInfo.contacts_name,
                      post: this.addressInfo.post_code,
                      id: this.addressInfo.id,
                    };
                  });
                  this.tableList = JSON.parse(JSON.stringify(data));
                  console.log(this.tableList, "d1weqeqweqwe");
                  let totalMoney = this.tableList.map(i => i.totalPrice);
                  this.totalMoney = this.getSum(totalMoney);
                  let totalOrderMun = this.tableList.map(i => i.sku.length);
                  this.totalOrderMun = this.getSum(totalOrderMun);
                  console.log(this.totalMoney);
                }
              }
            });
        }
      });
    },
    getCompanyData() {
      let api = "/shop/public/common/address/get_default";
      this.$axios.get(api).then(res2 => {
        if (res2.code == 200) {
          console.log(res2);
          let data = res2.data;
          if (data && data.id) {
            this.addressInfo = data;
            this.is_address = true;
          } else {
            // this.$message.warning(res2.msg);
            this.$message.warning("未设置配送收货地址");
            this.is_address = false;
          }
          this.$axios
            .postJSON("/shop/public/common/order_submit_view", {
              sku_info: this.sku_info,
              page_size: 10000,
            })
            .then(res => {
              if (res.code == 200) {
                if (res.data.data && res.data.data.length > 0) {
                  let data = res.data.data;
                  data.forEach(el => {
                    let total = el.sku.map(i => {
                      let obj = i.com_sku?.price * Number(i.buy_count);
                      return obj;
                    });
                    el["totalPrice"] = this.getSum(total);
                    el["address"] = {
                      province_name: this.addressInfo.province,
                      city_name: this.addressInfo.city,
                      area_name: this.addressInfo.area,
                      address: this.addressInfo.address,
                      mobile: this.addressInfo.contacts_mobile,
                      name: this.addressInfo.contacts_name,
                      post: this.addressInfo?.post_code,
                      id: this.addressInfo.id,
                    };
                  });
                  this.tableList = JSON.parse(JSON.stringify(data));
                  console.log(this.tableList, "d1weqeqweqwe");
                  let totalMoney = this.tableList.map(i => i.totalPrice);
                  this.totalMoney = this.getSum(totalMoney);
                  let totalOrderMun = this.tableList.map(i => i.sku.length);
                  this.totalOrderMun = this.getSum(totalOrderMun);
                  console.log(this.totalMoney);
                }
              }
            });
        }
      });
    },
    renderAddress(obj) {
      let text = `${obj.province_name}${obj.city_name}${obj.area_name}${obj.address}`;
      return text;
    },
    getSum(arr) {
      return eval(arr.join("+"));
    },
    changeAddress(item) {
      // let type = 1; //0企业1个人
      // if (this.$route.query.type) {
      //   type = 0;
      // }
      // console.log(type, 1111);
      if (this.type == 1) {
        this.showAddress = true;
        this.$nextTick(() => {
          this.$refs.addressListDraw.open({ addressInfo: item.address }, val => {
            console.log(val); // 返回所选择的参数
            if (val) {
              let obj = {
                province_name: val.province,
                city_name: val.city,
                area_name: val.area,
                address: val.address,
                mobile: val.contacts_mobile,
                name: val.contacts_name,
                post: val.post_code,
                id: val.id,
              };
              item.address = JSON.parse(JSON.stringify(obj));
              this.is_address = true;
            }
          });
        });
      } else {
        let chosedID = item.address?.id || 0;
        this.$refs.addressDraw.open({ title: "选择地址", chosedID }, val => {
          console.log(val);
          if (val) {
            let obj = {
              province_name: val.province,
              city_name: val.city,
              area_name: val.area,
              address: val.address,
              mobile: val.contacts_mobile,
              name: val.contacts_name,
              post: val.post_code,
              id: val.id,
            };
            item.address = JSON.parse(JSON.stringify(obj));
            console.log(item);
            this.is_address = true;
          }
        });
      }
    },
    closeAddress(value) {
      this.showAddress = value;
    },
    gotoHomePage(item) {
      this.link = location.origin + "/_shop/index?cid=" + item.seller_company_id;
      window.open(this.link);
    },

    async sumbit() {
      if (!this.is_address) {
        return this.$message.error("请先设置配送地址");
      }
      console.log(this.tableList);
      let newArr = this.tableList.flatMap(item =>
        item.sku.map(skuItem => ({
          ...skuItem,
          address: item.address,
          seller_company_code: item.seller_company_code,
          seller_company_id: item.seller_company_id,
          seller_company_logo: item.seller_company_logo,
          seller_company_name: item.seller_company_name,
          seller_company_serial_number: item.seller_company_code,
          seller_department_id: item.belong_department_id,
          totalPrice: item.totalPrice,
        }))
      );
      let order_com_sku = [];
      let api = "";
      if (this.type == 0) {
        let userInfo = null;
        api = "/shop/public/common/personal/company_create";
        await this.$axios.get("/base/user/login_info").then(res => {
          if (res.code == 200) {
            userInfo = res.data;
          }
        });
        let staff_main_department = userInfo.staff_main_department;
        let buyer_department_id = 0;
        if (staff_main_department && staff_main_department.id) {
          buyer_department_id = staff_main_department.id;
        }
        order_com_sku = newArr.map(it => {
          let objj = {
            buyer_department_id: buyer_department_id, //购方-主部门id
            com_sku_id: it.com_sku.id, //商品id
            unit_id: it.com_sku?.com_sku_goods?.goods_unit_id || 0, //物品单位id
            unit_name: it.com_sku?.com_sku_goods?.goods_unit_name || "", //基本单位
            price_unit_id: it.com_sku?.com_sku_goods?.goods_unit_id || 0, //计价单位id
            price_unit_name: it.com_sku?.com_sku_goods?.goods_unit_name || "", //计价单位
            per_price: it.com_sku?.price || 0, //单价 元
            price_count: it.price_count, //计价数量
            buy_count: it.buy_count, //数量
            delivery_date: it.delivery_date || "", //交货日期
            delivery_cycle: it.delivery_date, //交货周期 天
            receipt_info: it.address, //交货地址
            department_id: it.department_id, //需求部门id
            department_name: it.department_name, //需求部门
            project_id: it.project_id || 0,
            project_name: it.project_name || null, //项目
            cover_img_path: it.com_sku?.cover_img_path || "", //商品图片
            seller_department_id: it.seller_department_id, //销售方部门id
            seller_company_id: it.seller_company_id, //销售方组织id
            seller_company_name: it.seller_company_name, //销售方公司名称
            seller_company_code: it.seller_company_code, //销售方公司统一社会信用代码
            seller_company_serial_number: it.seller_company_serial_number, //销售方公司编号
            seller_company_logo: it.seller_company_logo, //销售方公司logo
          };
          return objj;
        });
      } else {
        api = "/shop/public/common/personal/create";
        order_com_sku = newArr.map(it => {
          let objj = {
            com_sku_id: it.com_sku.id, //商品id
            unit_id: it.com_sku?.com_sku_goods?.goods_unit_id || 0, //物品单位id
            unit_name: it.com_sku?.com_sku_goods?.goods_unit_name || "", //基本单位
            price_unit_id: it.com_sku?.com_sku_goods?.goods_unit_id || 0, //计价单位id
            price_unit_name: it.com_sku?.com_sku_goods?.goods_unit_name || "", //计价单位
            per_price: it.com_sku?.price || 0, //单价 元
            price_count: it.price_count, //计价数量
            buy_count: it.buy_count, //数量
            delivery_date: it.delivery_date || "", //交货日期
            delivery_cycle: it.delivery_date, //交货周期 天
            receipt_info: it.address, //交货地址
            department_id: it.department_id, //需求部门id
            department_name: it.department_name, //需求部门
            project_id: it.project_id || 0,
            project_name: it.project_name || null, //项目
            cover_img_path: it.com_sku?.cover_img_path || "", //商品图片
            seller_department_id: it.seller_department_id, //销售方部门id
            seller_company_id: it.seller_company_id, //销售方组织id
            seller_company_name: it.seller_company_name, //销售方公司名称
            seller_company_code: it.seller_company_code, //销售方公司统一社会信用代码
            seller_company_serial_number: it.seller_company_serial_number, //销售方公司编号
            seller_company_logo: it.seller_company_logo, //销售方公司logo
            buyer_user_mobile: this.buyer_user_mobile, //用户手机号
          };
          return objj;
        });
      }
      let params = {
        order_com_sku,
        pay_model: this.pay_model,
      };
      console.log(params);
      this.$axios.postJSON(api, params).then(res => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "下单成功!",
          });
          if (this.pay_model == 1) {
            /* 跳转 */
            let data = {
              token: sessionStorage.getItem("AccessToken"),
              domin: location.origin,
              order_id: res.data.id,
              order_type: "shop",
            };
            console.log(data, "data");
            location.href = `https://pay.feiwin.cn/?data=${JSON.stringify(data)}`;
          } else {
            setTimeout(() => {
              if (this.type == 1) {
                //个人
                this.$router.push({
                  path: "/myOrder/detail",
                  query: {
                    id: res.data.id,
                  },
                });
              } else {
                location.href =
                  location.origin + `/main/_workshop/shopOrder/buyerOrder/detail?id=${res.data.id}`;
              }
            }, 1000);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.head_info_bar {
  background-color: #fff;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.head_info {
  display: flex;
  line-height: 45px;
  padding: 0 36px;
  margin-bottom: 15px;
  color: #101010;
  font-weight: 600;
  margin-bottom: 5px;
}
.main_box {
  background-color: #fff;
  padding: 10px 36px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  .main_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
    }
    i {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .main_info {
    display: flex;
    align-items: center;
    margin: 15px 0;
    min-height: 120px;
    box-sizing: border-box;
  }
  .main_bot {
    margin-top: 25px;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    div {
      display: flex;
      align-items: center;
    }
  }

  .price_bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 50px;
    p {
      margin: 0 50px;
    }
  }
}
.bot {
  background-color: #fff;
  height: 166px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  .part1 {
    height: 30px;
    line-height: 30px;
  }
  .part2 {
    height: 70px;
    line-height: 70px;
    border-top: 1px solid #f8f8f8;
    margin-top: 10px;
  }
}

/* CSS */
.button-24 {
  width: 100%;
  background: #f74848;
  border: 1px solid #f74848;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #ff4742;
}

.button-24:active {
  opacity: 0.5;
}
</style>
