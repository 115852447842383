<template>
  <el-drawer
    :visible.sync="visible"
    :wrapperClosable="false"
    :with-header="false"
    direction="rtl"
    size="80%"
    id="addS1"
    :destroy-on-close="true"
  >
    <el-container style="height: 100%">
      <el-header height="auto">
        <fw-header :titles="title" />
      </el-header>
      <el-main>
        <el-row style="margin: 15px 0">
          <el-col align="right">
            <el-input
              style="width: 345px"
              placeholder="请输入地址别名"
              v-model="name"
              class="input-with-select"
              clearable
            >
              <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
            </el-input>

            <el-button @click="addBtn" style="margin-left: 20px" type="primary">+新增</el-button>
          </el-col>
        </el-row>
        <vxe-table
          class="mytable-scrollbar"
          stripe
          highlight-hover-row
          size="small"
          id="toolbar_demo3"
          :row-config="{ height: 120 }"
          ref="xTable1"
          :data="listData"
          :checkbox-config="{ checkMethod: checCheckboxkMethod2 }"
          :custom-config="{
            storage: true,
          }"
          auto-resize
        >
          <vxe-column v-if="type == 'radio'" type="radio" width="60" fixed="left"></vxe-column>
          <vxe-column v-else type="checkbox" width="60" fixed="left"></vxe-column>
          <vxe-column
            fixed="left"
            type="seq"
            title="序号"
            width="50"
            align="center"
            field="xuhao"
          ></vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <p>地址编号</p>
              <p>地址别名</p>
            </template>
            <template #default="{ row }">
              <template v-if="row.is_input">
                <div class="mt">
                  <el-input
                    placeholder="请输入"
                    v-model="row.serial_number"
                    maxlength="32"
                    show-word-limit
                  ></el-input>
                </div>
                <div>
                  <el-input
                    placeholder="请输入"
                    v-model="row.alias"
                    maxlength="32"
                    show-word-limit
                  ></el-input>
                </div>
              </template>
              <template v-else>
                <p class="ellipsisText" :title="row.serial_number || '--'">
                  {{ row.serial_number || "--" }}
                </p>
                <div style="display:flex">
                  <p class="ellipsisText" :title="row.alias || '--'">
                    {{ row.alias || "--" }}
                  </p>
                  <el-tag v-if="row.is_default" size="mini" class="tags">默认</el-tag>
                </div>
              </template>
            </template>
          </vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <p>联系人</p>
              <p>联系方式</p>
            </template>
            <template #default="{ row }">
              <template v-if="row.is_input">
                <div class="mt">
                  <el-input
                    placeholder="请输入"
                    v-model="row.contacts_name"
                    maxlength="32"
                    show-word-limit
                  ></el-input>
                </div>
                <div>
                  <el-input
                    placeholder="请输入"
                    v-model="row.contacts_mobile"
                    maxlength="32"
                    show-word-limit
                  ></el-input>
                </div>
              </template>
              <template v-else>
                <p class="ellipsisText" :title="row.contacts_name || '--'">
                  {{ row.contacts_name || "--" }}
                </p>
                <p class="ellipsisText" :title="row.contacts_mobile || '--'">
                  {{ row.contacts_mobile || "--" }}
                </p>
              </template>
            </template>
          </vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <p>所在地区</p>
              <p>街道地址</p>
            </template>
            <template #default="{ row }">
              <template v-if="row.is_input">
                <fw-map
                  :change="true"
                  :address="row.addressObj"
                  :showInput="true"
                  :onlyAddress="false"
                  @mapAddress="
                    val => {
                      setAddress(val, row);
                    }
                  "
                />
              </template>
              <template v-else>
                <p class="ellipsisText" :title="renderAddress(row.addressObj)">
                  {{ renderAddress(row.addressObj) }}
                </p>
              </template>
            </template>
          </vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <p>创建人</p>
              <p>创建时间</p>
            </template>
            <template #default="{ row }">
              <p class="ellipsisText" :title="row.created_by || '--'">
                {{ row.created_by || "--" }}
              </p>
              <p class="duohangEllipsisTextTwo" :title="row.created_at || '--'">
                {{ row.created_at || "--" }}
              </p>
            </template>
          </vxe-column>
          <vxe-column width="160">
            <template #header>
              <p>更新人</p>
              <p>更新时间</p>
            </template>
            <template #default="{ row }">
              <p class="ellipsisText" :title="row.updated_by || '--'">
                {{ row.updated_by || "--" }}
              </p>
              <p class="ellipsisText" :title="row.updated_at || '--'">
                {{ row.updated_at || "--" }}
              </p>
            </template>
          </vxe-column>
          <vxe-column title="操作" width="140" fixed="right">
            <template #default="{ row, $rowIndex }">
              <template v-if="row.created_by == '系统'">
                /
              </template>
              <template v-else>
                <div v-if="row.is_input">
                  <el-link :underline="false" class="links" @click="confirm(row)">确定</el-link>
                  <el-link :underline="false" class="links" @click="cancel(row, $rowIndex)">
                    取消
                  </el-link>
                </div>
                <div v-else>
                  <el-link :underline="false" @click="defaultBtn(row)" class="links" v-if="!row.is_default">
                    设为默认
                  </el-link>
                  <el-link :underline="false" @click="editBtn(row)" class="links">
                    编辑
                  </el-link>
                  <el-link :underline="false" @click="removeBtn(row, $rowIndex)" class="links">
                    删除
                  </el-link>
                </div>
              </template>
            </template>
          </vxe-column>
        </vxe-table>
      </el-main>
      <fw-pageNumber align="right" :pages="pages" @changePage="changePage" />
      <el-footer>
        <el-row>
          <el-col :span="24" align="center">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" type="primary" @click="confirmBtn">提交</el-button>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </el-drawer>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  created() {},
  mounted() {},
  data() {
    return {
      visible: false,
      pages: {
        //页码
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      listData: [],
      callFn: null, //回调
      chosedID: null,

      type: "radio",
      title: "选择地址",
      defaultParmas: {},
      name: "",
    };
  },
  watch: {},
  computed: {
    renderAddress() {
      return obj => {
        if (obj) {
          let msg = obj.province + obj.city + obj.area + obj.address;
          return msg;
        } else {
          return "--";
        }
      };
    },
  },
  methods: {
    open(data, callFn) {
      console.log(data, "data");
      if (data && data.chosedID) {
        //所选内容ID,没有不传
        this.chosedID = data.chosedID;
      }
      if (data && data.type) {
        //是否单选,默认单选
        this.type = data.type;
      }
      if (data && data.title) {
        this.title = data.title;
      }
      if (data && data.defaultParmas) {
        //是否单选,默认单选
        this.defaultParmas = data.defaultParmas;
        console.log(this.defaultParmas);
      }
      this.pages.pageNum = 1;
      this.name = "";
      this.query();
      this.callFn = callFn;
      this.visible = true;
    },
    query() {
      let params = {
        ...this.defaultParmas,
        name: this.name,
        page: this.pages.pageNum,
      };
      if (!this.name) {
        delete params.name;
      }
      let api = `/shop/public/common/address/list`;
      this.$axios.postJSON(api, params).then(res => {
        if (res.code == 200) {
          if (res.data) {
            let data = res.data.data;
            this.listData = data.map(v => {
              v["is_input"] = false;
              v["isAdd"] = false;
              v["addressObj"] = {
                province: v.province,
                city: v.city,
                area: v.area,
                address: v.address,
                lat: v.lat,
                lng: v.lng,
              };
              return v;
            });
          }
          this.pages.total = res.data.total;
          if (this.type == "radio") {
            let index = this.listData.findIndex(v => v.id === this.chosedID);
            if (index > -1) {
              this.$refs.xTable1.setRadioRow(this.listData[index]);
            }
          }
        }
      });
    },
    defaultBtn(row) {
      let parmas = { id: row.id };
      let api = `/shop/public/common/address/default`;
      this.$axios.putJSON(api, parmas).then(res => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.query();
        }
      });
    },

    checCheckboxkMethod2({ row }) {
      if (this.type != "radio") {
        if (!this.chosedID) this.chosedID = [];
        return !this.chosedID.includes(row.id);
      }
    },

    changePage(val) {
      if (val.type == "size") {
        this.pages.pageSize = val.num;
      } else {
        this.pages.pageNum = val.num;
      }
      this.query();
    },
    confirmBtn() {
      if (this.type == "radio") {
        let radio = this.$refs.xTable1.getRadioRecord();
        console.log("radio", radio);
        if (!radio) return this.$message.error("请先勾选数据");
        this.visible = false;
        this.callFn && this.callFn(radio);
      } else {
        const records = this.$refs.xTable1.getCheckboxRecords();
        if (records && records.length == 0) return this.$message.error("请先勾选数据");
        this.visible = false;
        this.callFn && this.callFn(records);
      }
    },
    close() {
      this.visible = false;
    },
    setAddress(val, row) {
      //收货地址
      console.log(val, row);
      let obj = JSON.parse(val);
      console.log(obj);
      row.addressObj = JSON.parse(JSON.stringify(obj));
      row.province = obj.province;
      row.city = obj.city;
      row.area = obj.area;
      row.address = obj.address;
      row.lat = obj.lat;
      row.lng = obj.lng;
    },

    addBtn() {
      let flag = this.listData.every(v => v.is_input == false);
      // console.log(flag);
      if (!flag) {
        return;
      }
      this.listData.push({
        serial_number: "", //地址编号
        alias: "", //地址别名
        contacts_name: "", //联系人姓名
        contacts_mobile: "", //联系人联系方式
        province: "", //所在省份
        city: "", //所在城市
        area: "", //所在区县
        address: "", //详细地址
        lng: 0, //经度
        lat: 0, //纬度
        desc: "", //备注
        is_input: true,
        isAdd: true,
      });
    },
    editBtn(row) {
      row.is_input = true;
      let {
        serial_number,
        alias,
        contacts_name,
        contacts_mobile,
        province,
        city,
        area, //所在区县
        address, //详细地址
        lng, //经度
        lat, //纬度
        desc, //备注
      } = row;
      row.saveData = {
        serial_number,
        alias,
        contacts_name,
        contacts_mobile,
        province,
        city,
        area, //所在区县
        address, //详细地址
        lng, //经度
        lat, //纬度
        desc, //备注
      };
    },
    removeBtn(row, index) {
      this.$confirm(`是否确认删除？`, "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let api = `/shop/public/common/address/delete`;
          this.$axios.deltJSON(api, { id: row.id }).then(res => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.query();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    confirm(row) {
      //确认
      let {
        serial_number,
        alias,
        contacts_name,
        contacts_mobile,
        province,
        city,
        area, //所在区县
        address, //详细地址
        lng, //经度
        lat, //纬度
        desc, //备注
      } = row;
      if (!serial_number) {
        return this.$message.error("请输入地址编号");
      }
      if (!contacts_name) {
        return this.$message.error("请输入联系人");
      }
      if (!contacts_mobile) {
        return this.$message.error("请输入联系方式");
      }
      if (!address) {
        return this.$message.error("请输入所在地址街道地址");
      }
      let parmas = {
        serial_number,
        alias,
        contacts_name,
        contacts_mobile,
        province,
        city,
        area, //所在区县
        address, //详细地址
        lng, //经度
        lat, //纬度
        desc, //备注
      };
      if (row.id) {
        parmas.id = row.id;
        let api = `/shop/public/common/address/update`;
        this.$axios.putJSON(api, parmas).then(res => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.query();
          }
        });
      } else {
        let api = `/shop/public/common/address/create`;
        this.$axios.postJSON(api, parmas).then(res => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.query();
          }
        });
      }
    },
    cancel(row, index) {
      //取消
      if (row.isAdd) {
        this.listData.splice(index, 1);
      } else {
        this.query();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding-left: 25px;
  padding-right: 25px;
}

.links {
  margin-right: 5px;
  color: #409eff;
}
.colors {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  // border-radius: 50%;
}

.colors2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
.mt {
  margin-bottom: 12px;
}
.tags {
  margin-left: 5px;
  border-radius: 10px;
  width: 45px;
  text-align: center;
}
</style>
