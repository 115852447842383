var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-drawer',{attrs:{"visible":_vm.visible,"wrapperClosable":false,"with-header":false,"direction":"rtl","size":"80%","id":"addS1","destroy-on-close":true},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-container',{staticStyle:{"height":"100%"}},[_c('el-header',{attrs:{"height":"auto"}},[_c('fw-header',{attrs:{"titles":_vm.title}})],1),_c('el-main',[_c('el-row',{staticStyle:{"margin":"15px 0"}},[_c('el-col',{attrs:{"align":"right"}},[_c('el-input',{staticClass:"input-with-select",staticStyle:{"width":"345px"},attrs:{"placeholder":"请输入地址别名","clearable":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.query},slot:"append"})],1),_c('el-button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary"},on:{"click":_vm.addBtn}},[_vm._v("+新增")])],1)],1),_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",attrs:{"stripe":"","highlight-hover-row":"","size":"small","id":"toolbar_demo3","row-config":{ height: 120 },"data":_vm.listData,"checkbox-config":{ checkMethod: _vm.checCheckboxkMethod2 },"custom-config":{
          storage: true,
        },"auto-resize":""}},[(_vm.type == 'radio')?_c('vxe-column',{attrs:{"type":"radio","width":"60","fixed":"left"}}):_c('vxe-column',{attrs:{"type":"checkbox","width":"60","fixed":"left"}}),_c('vxe-column',{attrs:{"fixed":"left","type":"seq","title":"序号","width":"50","align":"center","field":"xuhao"}}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("地址编号")]),_c('p',[_vm._v("地址别名")])]},proxy:true},{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.is_input)?[_c('div',{staticClass:"mt"},[_c('el-input',{attrs:{"placeholder":"请输入","maxlength":"32","show-word-limit":""},model:{value:(row.serial_number),callback:function ($$v) {_vm.$set(row, "serial_number", $$v)},expression:"row.serial_number"}})],1),_c('div',[_c('el-input',{attrs:{"placeholder":"请输入","maxlength":"32","show-word-limit":""},model:{value:(row.alias),callback:function ($$v) {_vm.$set(row, "alias", $$v)},expression:"row.alias"}})],1)]:[_c('p',{staticClass:"ellipsisText",attrs:{"title":row.serial_number || '--'}},[_vm._v(" "+_vm._s(row.serial_number || "--")+" ")]),_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticClass:"ellipsisText",attrs:{"title":row.alias || '--'}},[_vm._v(" "+_vm._s(row.alias || "--")+" ")]),(row.is_default)?_c('el-tag',{staticClass:"tags",attrs:{"size":"mini"}},[_vm._v("默认")]):_vm._e()],1)]]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("联系人")]),_c('p',[_vm._v("联系方式")])]},proxy:true},{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.is_input)?[_c('div',{staticClass:"mt"},[_c('el-input',{attrs:{"placeholder":"请输入","maxlength":"32","show-word-limit":""},model:{value:(row.contacts_name),callback:function ($$v) {_vm.$set(row, "contacts_name", $$v)},expression:"row.contacts_name"}})],1),_c('div',[_c('el-input',{attrs:{"placeholder":"请输入","maxlength":"32","show-word-limit":""},model:{value:(row.contacts_mobile),callback:function ($$v) {_vm.$set(row, "contacts_mobile", $$v)},expression:"row.contacts_mobile"}})],1)]:[_c('p',{staticClass:"ellipsisText",attrs:{"title":row.contacts_name || '--'}},[_vm._v(" "+_vm._s(row.contacts_name || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.contacts_mobile || '--'}},[_vm._v(" "+_vm._s(row.contacts_mobile || "--")+" ")])]]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("所在地区")]),_c('p',[_vm._v("街道地址")])]},proxy:true},{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.is_input)?[_c('fw-map',{attrs:{"change":true,"address":row.addressObj,"showInput":true,"onlyAddress":false},on:{"mapAddress":function (val) {
                    _vm.setAddress(val, row);
                  }}})]:[_c('p',{staticClass:"ellipsisText",attrs:{"title":_vm.renderAddress(row.addressObj)}},[_vm._v(" "+_vm._s(_vm.renderAddress(row.addressObj))+" ")])]]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("创建人")]),_c('p',[_vm._v("创建时间")])]},proxy:true},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.created_by || '--'}},[_vm._v(" "+_vm._s(row.created_by || "--")+" ")]),_c('p',{staticClass:"duohangEllipsisTextTwo",attrs:{"title":row.created_at || '--'}},[_vm._v(" "+_vm._s(row.created_at || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("更新人")]),_c('p',[_vm._v("更新时间")])]},proxy:true},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.updated_by || '--'}},[_vm._v(" "+_vm._s(row.updated_by || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.updated_at || '--'}},[_vm._v(" "+_vm._s(row.updated_at || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"title":"操作","width":"140","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
                  var $rowIndex = ref.$rowIndex;
return [(row.created_by == '系统')?[_vm._v(" / ")]:[(row.is_input)?_c('div',[_c('el-link',{staticClass:"links",attrs:{"underline":false},on:{"click":function($event){return _vm.confirm(row)}}},[_vm._v("确定")]),_c('el-link',{staticClass:"links",attrs:{"underline":false},on:{"click":function($event){return _vm.cancel(row, $rowIndex)}}},[_vm._v(" 取消 ")])],1):_c('div',[(!row.is_default)?_c('el-link',{staticClass:"links",attrs:{"underline":false},on:{"click":function($event){return _vm.defaultBtn(row)}}},[_vm._v(" 设为默认 ")]):_vm._e(),_c('el-link',{staticClass:"links",attrs:{"underline":false},on:{"click":function($event){return _vm.editBtn(row)}}},[_vm._v(" 编辑 ")]),_c('el-link',{staticClass:"links",attrs:{"underline":false},on:{"click":function($event){return _vm.removeBtn(row, $rowIndex)}}},[_vm._v(" 删除 ")])],1)]]}}])})],1)],1),_c('fw-pageNumber',{attrs:{"align":"right","pages":_vm.pages},on:{"changePage":_vm.changePage}}),_c('el-footer',[_c('el-row',[_c('el-col',{attrs:{"span":24,"align":"center"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.confirmBtn}},[_vm._v("提交")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }