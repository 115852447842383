var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.drawer)?_c('el-drawer',{attrs:{"visible":_vm.drawer,"wrapperClosable":false,"with-header":false,"direction":"rtl","size":"80%","id":"addSucai","destroy-on-close":true},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('el-container',{staticStyle:{"height":"100%"}},[_c('el-header',{attrs:{"height":"auto"}},[_c('fw-header',{attrs:{"titles":"选择地址"}})],1),_c('el-main',[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",attrs:{"stripe":"","highlight-hover-row":"","size":"small","border":false,"id":"toolbar_demo3","data":_vm.listData,"row-id":_vm.defaultKey,"radio-config":{ checkRowKey: _vm.defaultSelecteRow },"row-key":""}},[_c('vxe-column',{attrs:{"type":"radio","width":"60"}}),_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"60"}}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("联系人姓名")]),_c('div',[_vm._v("手机号")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"ellipsisText",attrs:{"title":row.contacts_name || '--'}},[_vm._v(" "+_vm._s(row.contacts_name || "--")+" ")]),_c('div',{staticClass:"ellipsisText",attrs:{"title":row.contacts_mobile || '--'}},[_vm._v(" "+_vm._s(row.contacts_mobile || "--")+" ")])]}}],null,false,1178256464)}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("所在地区")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"ellipsisText",attrs:{"title":row.province + row.city + row.area || '--'}},[_vm._v(" "+_vm._s(row.province + row.city + row.area)+" ")])]}}],null,false,2261261654)}),_c('vxe-column',{attrs:{"min-width":"200"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("街道地址")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"ellipsisText",attrs:{"title":row.address || '--'}},[_vm._v(" "+_vm._s(row.address || "--")+" ")])]}}],null,false,2292514080)}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("邮政编码")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"ellipsisText",attrs:{"title":row.post_code || '--'}},[_vm._v(" "+_vm._s(row.post_code || "--")+" ")])]}}],null,false,749325170)}),_c('vxe-column',{attrs:{"title":"创建人","width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("创建人")]),_c('div',[_vm._v("创建时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.created_by || "--"))]),_c('div',[_vm._v(_vm._s(row.created_at || "--"))])]}}],null,false,625521707)}),_c('vxe-column',{attrs:{"title":"更新人","width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("更新人")]),_c('div',[_vm._v("更新时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.updated_by || "--"))]),_c('div',[_vm._v(_vm._s(row.updated_at || "--"))])]}}],null,false,166158347)})],1)],1),_c('el-footer',{staticStyle:{"margin":"10px 0"}},[_c('el-row',[_c('el-col',{attrs:{"align":"center","span":24}},[_c('span',{staticClass:"chongzhiPlain"},[_c('el-button',{staticStyle:{"width":"88px"},attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"width":"88px"},attrs:{"size":"small","type":"primary"},on:{"click":_vm.sumbit}},[_vm._v(" 提交 ")])],1)])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }